<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
const store = useStore();

const { frame_stages_array } = defineProps({
  frame_stages_array: {
    type: Array,
    required: true,
  },
});

const selected_frame_stages = store.getters.selectedStages;
// console.log("SELECTED FRAME STAGES: ", selected_frame_stages);

//const filtered_stages = frame_stages_array merged with selected_frame_stages and keep only the matching values
const filtered_stages = frame_stages_array.filter((frame_stage_id) =>
  selected_frame_stages.includes(frame_stage_id)
);
// console.log("FILTERED STAGES: ", filtered_stages);

//convert the stages collection to a map so we have a constant time complexity when we accessing a stage
const StagesMap = new Map(
  store.getters.stages.map((stage) => [stage.frame_stage_id, stage])
);
//map the frame_stages_array to the stages Map
const stages = computed(() =>
  filtered_stages.map((stage) => StagesMap.get(stage))
);

// console.log("FULL STAGES: ", stages);
</script>

<template>
  <main class="stage-badges-wrapper">
    <div
      v-for="stage in stages"
      :key="stage"
      :class="[stages.length === 1 ? 'single-badge-wrapper' : 'badge-wrapper']"
      :style="`border-color: ${stage.color_code};`"
    >
      <p class="-stb" :style="`color: ${stage.color_code};`">
        {{ stage.stage_value }}
      </p>
    </div>
  </main>
</template>

<style scoped>
.stage-badges-wrapper {
  width: 100%;
  display: flex;
  --badge-radius: 4px;
}
.single-badge-wrapper,
.badge-wrapper {
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid;
}
.single-badge-wrapper {
  border-radius: var(--badge-radius);
}

.badge-wrapper:first-child {
  border-top-left-radius: var(--badge-radius);
  border-bottom-left-radius: var(--badge-radius);
}
.badge-wrapper:last-child {
  border-top-right-radius: var(--badge-radius);
  border-bottom-right-radius: var(--badge-radius);
}
</style>
