<script setup>
import DynaBadge from "./DynaBadge.vue";
import { computed } from "vue";

const { orgs } = defineProps({
  orgs: {
    type: Array,
    required: true,
  },
});

const uniqueOrgs = [...new Set(orgs.map(JSON.stringify))].map(JSON.parse);

const emptyString = "Not Assigned";
const MAX_ITEMS_TO_RENDER = 4;

const displayedOrgs = computed(() => {
  return uniqueOrgs.slice(0, MAX_ITEMS_TO_RENDER);
});

const remainingOrgsCount = computed(() => {
  return Math.max(uniqueOrgs.length - MAX_ITEMS_TO_RENDER, 0);
});

const remainingUsersList = computed(() => {
  return orgs
    .slice(MAX_ITEMS_TO_RENDER, uniqueOrgs.length)
    .map(({ company_name, email }) => `${company_name} ${email}`)
    .join("\n");
});
</script>

<template>
  <main class="handler-wrapper">
    <section v-if="!uniqueOrgs.length">
      <section class="flex-row">
        <div>
          <DynaBadge />
        </div>
        <div class="flex-column">
          <p class="-stb">{{ emptyString }}</p>
        </div>
      </section>
    </section>

    <section v-else-if="uniqueOrgs.length === 1">
      <section class="flex-row">
        <div>
          <DynaBadge
            :picturePath="uniqueOrgs[0].logo"
            :first_name="uniqueOrgs[0].company_name"
          />
        </div>
        <div class="flex-column">
          <p class="-stb">{{ uniqueOrgs[0].company_name }}</p>
          <!-- <p class="-str">{{ uniqueOrgs[0].email }}</p> -->
        </div>
      </section>
    </section>

    <section v-else>
      <section class="flex-row">
        <div
          class="overlapped-badge"
          v-for="org in displayedOrgs"
          :key="org.id"
        >
          <DynaBadge :picturePath="org.logo" :first_name="org.company_name" />
        </div>
        <div
          v-if="remainingOrgsCount > 0"
          class="remaining-orgs-wrapper"
          :title="remainingUsersList"
        >
          <p class="-xstb">{{ `+ ${remainingOrgsCount}` }}</p>
        </div>
      </section>
    </section>
  </main>
</template>

<style scoped>
* {
  --negative-margin: -1rem;
}
.overlapped-badge:not(:first-child) {
  margin-left: var(--negative-margin);
}
.remaining-orgs-wrapper {
  width: 40px;
  min-width: 40px;
  height: 40px;
  min-height: 40px;
  border-radius: 50%;
  background: var(--focus3);
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: var(--focus2) 0 0 0 1px;
  margin-left: var(--negative-margin);
  color: var(--focus-text);
}
</style>
