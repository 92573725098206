<template>
  <main class="content-page">
    <section class="submenu">
      <span class="highlight"></span>
      <h1 class="-h6">Agents</h1>

      <section class="actions">
        <div class="btn-container">
          <Button
            :state="'primary'"
            :title="'Create New Agent'"
            @click="setModal('create')"
          />
        </div>
        <div class="btn-container">
          <Button
            :state="'primary'"
            :title="'Invite Member'"
            @click="setModal('invite')"
          />
        </div>
        <div class="search-container">
          <SearchBar />
        </div>
      </section>
    </section>

    <section class="no-data" v-if="!agents.length">
      <NoData :item="'agent'" />
    </section>
    <section class="listing" v-else>
      <AgentsListing :agents="agents" @setModal="setModal" />
    </section>
  </main>
</template>

<script>
import SearchBar from "../../../components/utils/BaseSearchBar.vue";
import Button from "../../../components/utils/BaseButton.vue";
import AgentsListing from "../../../components/tables/mscope/agents/AgentsListing.vue";
import NoData from "../../../components/utils/NoData.vue";
import { getUnitAgents } from "../../../services/requests/get/agents";

export default {
  props: ["getRouteName"],
  components: { SearchBar, Button, AgentsListing, NoData },
  data() {
    return {
      agents: [],
    };
  },
  methods: {
    async getAgents(selectedStages) {
      try {
        // const stagesArray = this.getStagesArray(selectedStages);
        // const res = await getUnitAgents(stagesArray, true);
        // console.log(res);

        // if (res.status != 200) return;

        // this.agents = res.data;

        //*** DELETE ME WHEN API IS DONE ***//
        const module = await import("../../../assets/dummyDB/getAgents");
        this.agents = module.getAgents();
        console.log("DUMMY AGENTS :", this.agents);
        //*** *** ***//
      } catch (e) {
        console.log(e);
      }
    },
    getStagesArray(selectedStages) {
      return selectedStages || [this.$store.state.selectedStages];
    },
    setModal(cmd, item) {
      const route = this.getRouteName();
      const scopeModal = {
        cmd: cmd,
        scope: route,
        item: item,
      };
      this.$store.dispatch("scopeModal", scopeModal);
    },
  },
  watch: {
    "$store.state.selectedStages": {
      handler: async function (newVal) {
        await this.getAgents(newVal);
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style scoped>
* {
  --submenu-height: 40px;
}
.content-page {
  height: calc(100% - 4px);
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.submenu {
  width: 100%;
  height: var(--submenu-height);
  display: flex;
  align-items: center;
  /* border: 1px solid black; */
  padding-top: 8px;
}
.listing {
  width: 100%;
  height: calc(100% - var(--submenu-height));
  overflow: auto;
  animation: slide-in-top-menu 600ms ease-out;
}
.highlight {
  display: inline-block;
  height: 16px;
  width: 1px;
  border-radius: 8px;
  border: 2px solid var(--highlight);
  background: var(--highlight);
}
.submenu h1 {
  text-transform: uppercase;
  padding-left: 8px;
}
.actions {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 16px;
}
.search-container {
  height: 100%;
  width: 272px;
  text-align: right;
}
.btn-container {
  width: 164px;
  height: 100%;
  min-height: 32px;
}
@media screen and (max-width: 1023px) {
  .submenu {
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 16px;
  }
  .highlight {
    display: none;
  }
  .actions {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
}
</style>
