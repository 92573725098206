<script setup>
import DynaBadge from "./DynaBadge.vue";
import { computed } from "vue";

const { users } = defineProps({
  users: {
    type: Array,
    required: true,
  },
});

const emptyString = "Not Assigned";
const MAX_USERS_TO_RENDER = 4;

const displayedUsers = computed(() => {
  return users.slice(0, MAX_USERS_TO_RENDER);
});

const remainingUsersCount = computed(() => {
  return Math.max(users.length - MAX_USERS_TO_RENDER, 0);
});

const remainingUsersList = computed(() => {
  return users
    .slice(MAX_USERS_TO_RENDER, users.length)
    .map(({ first_name, last_name }) => `${first_name} ${last_name}`)
    .join("\n");
});

const fullName = computed(() => {
  return `${users[0].first_name} ${users[0].last_name}`;
});
</script>

<template>
  <main class="handler-wrapper">
    <section v-if="!users.length">
      <section class="flex-row">
        <div>
          <DynaBadge />
        </div>
        <div class="flex-column">
          <p class="-stb">{{ emptyString }}</p>
        </div>
      </section>
    </section>

    <section v-else-if="users.length === 1">
      <section class="flex-row">
        <div>
          <DynaBadge
            :picturePath="users[0].avatar"
            :first_name="users[0].first_name"
            :last_name="users[0].last_name"
          />
        </div>
        <div class="flex-column">
          <p class="-stb">{{ fullName }}</p>
          <p class="-str">{{ users[0].email }}</p>
        </div>
      </section>
    </section>

    <section v-else>
      <section class="flex-row">
        <div
          class="overlapped-badge"
          v-for="(user, index) in displayedUsers"
          :key="user.id"
        >
          <DynaBadge
            :picturePath="user.avatar"
            :first_name="user.first_name"
            :last_name="user.last_name"
          />
        </div>
        <div
          v-if="remainingUsersCount > 0"
          class="remaining-users-wrapper"
          :title="remainingUsersList"
        >
          <p class="-xstb">{{ `+ ${remainingUsersCount}` }}</p>
        </div>
      </section>
    </section>
  </main>
</template>

<style scoped>
* {
  --negative-margin: -1rem;
}
.overlapped-badge:not(:first-child) {
  margin-left: var(--negative-margin);
}
.remaining-users-wrapper {
  width: 40px;
  min-width: 40px;
  height: 40px;
  min-height: 40px;
  border-radius: 50%;
  background: var(--focus3);
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: var(--focus2) 0 0 0 1px;
  margin-left: var(--negative-margin);
  color: var(--focus-text);
}
</style>
