<script setup>
import DynaBadge from "../../../utils/DynaBadge.vue";
import StageBadges from "../../../utils/StageBadges.vue";
import { computed } from "vue";

const { user: props } = defineProps({
  user: {
    type: Object,
    required: true,
  },
  selectedStages: {
    type: Array,
    required: true,
  },
});

const {
  id,
  first_name,
  last_name,
  email,
  avatar,
  in_stages,
  role: { title: role_title },
  company: { company_name, logo },
} = props;

const getAgentRoleIcon = computed(() => {
  const role = role_title.toLowerCase();
  return require(`../../../../assets/icons/agentRoles/${role}.svg`);
});
</script>

<template>
  <tr class="tr-content">
    <td class="my-td">
      <div class="flex-row">
        <div class="i-wrapper">
          <img :src="getAgentRoleIcon" :alt="role_title" />
        </div>
        <p class="-stb">{{ role_title }}</p>
      </div>
    </td>
    <td class="my-td">
      <div class="flex-row">
        <div>
          <DynaBadge
            :picturePath="avatar"
            :first_name="first_name"
            :last_name="last_name"
          />
        </div>
        <div class="flex-column">
          <p class="-stb">{{ first_name }} {{ last_name }}</p>
          <p class="-str">{{ email }}</p>
        </div>
      </div>
    </td>
    <td class="my-td" colspan="2">
      <div class="flex-row">
        <div>
          <DynaBadge :picturePath="logo" :first_name="company_name" />
        </div>
        <div class="flex-column">
          <p class="-stb">{{ company_name }}</p>
        </div>
      </div>
    </td>
    <td class="my-td">
      <!-- <div class="-stb empty-tag">No squad assigned</div> -->
    </td>
    <td class="my-td" v-show="selectedStages.length > 1">
      <StageBadges :frame_stages_array="in_stages" />
    </td>
    <td></td>
  </tr>
</template>

<style scoped>
.tr-content {
  background: var(--gray6);
}
.tr-content:last-child {
  box-shadow: inset 0px -8px 8px -6px rgba(0, 0, 0, 0.1);
}
.tr-content:not(:last-child) {
  border-bottom: 1px solid var(--white2);
}
.my-td:first-child {
  padding-left: 2rem;
}
.flex-column {
  gap: 0;
}
.i-wrapper {
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.i-wrapper > img {
  object-fit: contain;
}
</style>
