<template>
  <main class="table-container">
    <table class="my-table">
      <thead class="my-thead">
        <tr>
          <th class="my-th" style="width: 20%; min-width: 150px">
            <p class="-stb">Responsibility</p>
          </th>
          <th class="my-th" style="width: 15%; min-width: 170px">
            <p class="-stb">Assigned To</p>
          </th>
          <th class="my-th" style="width: 15%; min-width: 170px">
            <p class="-stb">Organization</p>
          </th>
          <th class="my-th" style="width: 15%; min-width: 170px">
            <p class="-stb">Contracted by</p>
          </th>
          <th class="my-th" style="width: 15%; min-width: 350px">
            <p class="-stb">Squads</p>
          </th>
          <th
            class="my-th"
            style="width: 15%"
            v-show="selectedStages.length > 1"
          >
            <p class="-stb">Stage</p>
          </th>
          <th class="my-th" style="width: 5%"></th>
        </tr>
      </thead>
      <tbody v-for="(agent, index) in computedAgents" :key="agent">
        <tr
          class="tr-content"
          :class="[
            index % 2 ? 'bg-primary' : 'bg-secondary',
            { 'toggled-row': agent.is_toggled && getUsers[index]?.length },
          ]"
        >
          <td
            @click="agent.is_toggled = !agent.is_toggled"
            class="my-td item-hover"
          >
            <div class="flex-row">
              <div v-if="getUsers[index]?.length">
                <i
                  class="i-arrow-open-down"
                  :class="{ rotate: agent.is_toggled }"
                ></i>
              </div>
              <p
                class="-stb"
                :class="{ 'blank-arrow-ph': !getUsers[index]?.length }"
              >
                {{ agent.agent.agent_name }}
              </p>
            </div>
          </td>
          <td
            @click="agent.is_toggled = !agent.is_toggled"
            class="my-td item-hover"
          >
            <UserBadgesHandler :users="getUsers[index]" />
          </td>

          <td class="my-td">
            <OrgBadgesHandler :orgs="getOrgs[index]" />
          </td>

          <td class="my-td">
            <div class="flex-row" v-if="getContrator">
              <DynaBadge
                :picturePath="getContrator[index]?.logo"
                :first_name="getContrator[index]?.company_name"
              />
              <div class="flex-column">
                <p class="-stb">{{ getContrator[index]?.company_name }}</p>
              </div>
            </div>
            <div class="flex-row" v-else>
              <DynaBadge />
              <div class="flex-column">
                <!-- <p class="-stb">No Info Available</p> -->
              </div>
            </div>
          </td>

          <td class="my-td">
            <div class="flex-row" v-if="agent.squads">
              <p
                class="-stb tag"
                v-for="squad in shownSquads(agent.squads)"
                :key="squad"
              >
                {{ squad }}
              </p>
              <p
                class="-stb additional-tag"
                v-if="hiddenSquads(agent.squads) > 0"
              >
                + {{ hiddenSquads(agent.squads) }}
              </p>
            </div>
            <div class="-stb empty-tag" v-else>No squad assigned</div>
          </td>

          <td class="my-td" v-show="selectedStages.length > 1">
            <StageBadges :frame_stages_array="agent.stages" />
          </td>

          <td class="my-td" style="max-width: 40px">
            <IconEdit title="Edit Row" @click="setModal('update', agent)" />
          </td>
        </tr>

        <template v-for="user in agent.users" :key="user?.id">
          <AgentsSubRow
            v-show="agent.is_toggled"
            :user="user"
            :selectedStages="selectedStages"
          />
        </template>
      </tbody>
    </table>
  </main>
</template>

<script>
import { mapGetters } from "vuex";
import AgentsSubRow from "@/components/tables/mscope/agents/AgentsSubRow.vue";
import IconTrash from "../../../../assets/icons/dynamicIcons/IconTrash.vue";
import IconEdit from "../../../../assets/icons/dynamicIcons/IconEdit.vue";
import IconHierarchy from "../../../../assets/icons/dynamicIcons/IconHierarchy.vue";
import UserBadgesHandler from "../../../utils/UserBadgesHandler.vue";
import OrgBadgesHandler from "../../../utils/OrgBadgesHandler.vue";
import DynaBadge from "../../../utils/DynaBadge.vue";
import StageBadges from "../../../utils/StageBadges.vue";

export default {
  components: {
    AgentsSubRow,
    IconTrash,
    IconEdit,
    IconHierarchy,
    UserBadgesHandler,
    OrgBadgesHandler,
    DynaBadge,
    StageBadges,
  },
  emits: ["setModal"],
  props: ["agents"],
  data() {
    return {
      onBehalf: false,
    };
  },
  methods: {
    shownSquads(squads) {
      if (squads.length <= 3) {
        return squads;
      } else {
        const res = squads.slice(0, 3);
        return res;
      }
    },
    hiddenSquads(squads) {
      const res = squads.slice(3, squads.length).length;
      return res;
    },
    // DELETE ME AFTER
    getStage(frame_stage_id) {
      const stage = this.$store.getters.stages.find(
        (stage) => stage.frame_stage_id == frame_stage_id
      );

      return stage;
    },

    // will need mapGetters : user, selectedStages
    setModal(cmd, item) {
      this.$emit("setModal", cmd, item);
    },
  },
  computed: {
    //for demo purposes only
    computedAgents() {
      //return the agents where the selected stages are included in the agent's stages property (array)
      return this.agents.filter((agent) =>
        agent.stages.some((stage) => this.selectedStages.includes(stage))
      );
    },
    //
    ...mapGetters(["selectedStages"]),
    getUsers() {
      return this.computedAgents.map((agent) => agent.users);
      // const agentUsers = this.computedAgents.map((agent) => agent.users);
      // console.log(agentUsers);

      // return this.computedAgents.map((agent) => agent.users);
    },
    getOrgs() {
      // return this.agents.map((agent) => agent.companies);

      //return all companies inside each user of each agent
      return this.computedAgents.map((agent) =>
        agent.users.map((user) => user.company)
      );
    },
    getContrator() {
      return this.computedAgents.map((agent) => agent.contracted_by);
    },
    isValidUser() {
      return (user) => {
        //check the user.in_stages array if it includes any of the selected stages
        return user.in_stages.some((stage) =>
          this.selectedStages.includes(stage)
        );
      };
    },
  },
};
</script>

<style scoped>
.bg-primary {
  background: #f9fafb !important;
}
.bg-secondary {
  background: var(--white1) !important;
}
.table-container {
  width: 100%;
  height: auto;
  user-select: none;
}
.item-hover {
  cursor: pointer;
}
.my-table {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  transition: all 250ms ease;
}
.my-thead {
  background-image: linear-gradient(
    to right,
    var(--primary) 0%,
    #004e92 51%,
    var(--primary) 100%
  );
  background-size: 500% auto;
}

.my-th {
  padding: 8px;
  color: var(--white1);
}
.my-th:first-child {
  border-top-left-radius: 8px;
}
.my-th:last-child {
  border-top-right-radius: 8px;
}
:deep(.my-td) {
  padding: 8px;
}

/* .tr-content {
  transition: 100ms ease;
} */
.toggled-row {
  position: relative;
  box-shadow: 0px 8px 8px -6px rgba(0, 0, 0, 0.1);
  z-index: 1;
}
.tr-content:hover {
  background: rgb(243, 248, 254) !important;
}
.tr-toggled-content {
  /* border-block: 1px solid var(--gray5); */
  background: var(--gray6);
}
.tr-toggled-content:last-child {
  box-shadow: inset 0px -8px 8px -6px rgba(0, 0, 0, 0.1);
}
:deep(.flex-row) {
  display: flex;
  gap: 4px;
  justify-content: flex-start;
  align-items: center;
}
:deep(.flex-column) {
  display: flex;
  flex-direction: column;
}
:deep(.flex-column p:last-child) {
  color: var(--gray2);
}

.toggled {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 64px;
  padding-inline: 12px;
}
.toggled-text {
  color: var(--gray2);
}
/*ICONS*/
:deep(i) {
  transform: scale(0.5);
  cursor: pointer;
}

.rotate {
  transform: rotateZ(180deg) scale(0.5);
}

thead {
  position: sticky;
  top: 0;
  z-index: 2;
}

:deep(.tag),
:deep(.additional-tag),
:deep(.empty-tag) {
  display: inline;
  width: auto;
  padding-block: 4px;
  padding-inline: 12px;
  border-radius: 32px;
  background: #eff8ff;
  color: #175cd3;
}
:deep(.tag:first-child) {
  color: #6941c6;
  background: #f9f5ff;
}
:deep(.tag:last-child) {
  color: #3538cd;
  background: #eef4ff;
}
:deep(.additional-tag),
:deep(.empty-tag) {
  color: #344054;
  background: #f2f4f7;
  white-space: nowrap;
}
.blank-arrow-ph {
  padding-left: 36px;
}
</style>
